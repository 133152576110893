




























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class SideBar extends Vue {
  private items = []
  private initialized = false
  private showUnsupportedFeaturesInternal = false
  private selectedIntegrationId = null

  @Prop()
  private integrations

  @Prop()
  private value

  @Prop()
  private validationErrorsPerPage

  @Prop()
  private hasUnsavedChanges

  @Prop()
  private features

  @Prop()
  private showUnsupportedFeatures

  @Prop()
  private integration

  public mounted(): void {
    this.items = [
      {
        icon: 'fa-cogs',
        url: 'Settings/Economy/AccountingIntegration/Setup',
        title: 'Overview',
      },
      {
        icon: 'fa-cogs',
        url: 'Settings/Economy/AccountingIntegration/Setup/Page',
        title: 'Invoices',
        params: { page: 'invoices' },
      },
      {
        icon: 'fa-cogs',
        url: 'Settings/Economy/AccountingIntegration/Setup/Page',
        title: 'Receipts',
        params: { page: 'receipts' },
      },
      {
        icon: 'fa-cogs',
        url: 'Settings/Economy/AccountingIntegration/Setup/Page',
        title: 'SupplierInvoices',
        params: { page: 'supplier-invoices' },
      },
      {
        icon: 'fa-cogs',
        url: 'Settings/Economy/AccountingIntegration/Setup/Page',
        title: 'StartNumbers',
        params: { page: 'start-numbers' },
      },
      {
        icon: 'fa-cogs',
        url: 'Settings/Economy/AccountingIntegration/Setup/Page',
        title: 'OtherSettings',
        params: { page: 'other-settings' },
      },
      {
        icon: 'fa-cogs',
        url: 'Settings/Economy/AccountingIntegration/Setup/Page',
        title: 'Checks',
        params: { page: 'checks' },
      },
      {
        icon: 'fa-cogs',
        url: 'Settings/Economy/AccountingIntegration/Setup/Page',
        title: 'Save settings',
        params: { page: 'save' },
      },
    ]
    this.selectedIntegrationId = this.value
    this.initialized = true
    this.showUnsupportedFeaturesInternal = !!this.showUnsupportedFeatures
  }

  @Watch('selectedIntegrationId')
  private onChangeId() {
    this.$emit('input', this.selectedIntegrationId)
  }

  @Watch('showUnsupportedFeaturesInternal')
  private onChangeShowUnsupportedFeaturesInternal() {
    this.$emit('change-show-unsupported-features', this.showUnsupportedFeaturesInternal)
  }

  private getUrl(item) {
    if (item.url[0] === '/') {
      return item.url
    } else {
      return { name: item.url, params: item.params || {} }
    }
  }

  private get hasMultipleIntegrations(): boolean {
    return this.integrations && this.integrations.length > 1
  }

  private getValidationErrorCount(item): number {
    const page = item.params?.page || ''
    if (!page) {
      return 0
    }
    return this.validationErrorsPerPage[page] || 0
  }

  private isBadgeVisible(item): boolean {
    return this.getBadgeText(item) !== ''
  }

  private getBadgeText(item): string {
    if (item.params?.page === 'save') {
      return this.hasUnsavedChanges ? this.$t('c:accounting-integration:Unsaved changes') : ''
    }
    const errors = this.getValidationErrorCount(item)
    return errors > 0 ? this.$t('c:accounting-integration:%s errors').replace('%s', '' + errors) : ''
  }

  private isFeatureSupported(feature: string): boolean {
    return this.features?.isSupported(this.integration?.type, feature)
  }

  private isFeatureVisible(feature: string): boolean {
    return this.showUnsupportedFeatures || this.isFeatureSupported(feature)
  }

  private get isOtherSettingsVisible() {
    return this.isFeatureVisible('SetProductAccount') || this.isFeatureVisible('CustomerSyncMode')
  }

  private get isSupplierInvoicesVisible() {
    return (
      this.isFeatureVisible('SupplierNumberPrefix') ||
      this.isFeatureVisible('BookkeepSupplierInvoices') ||
      this.isFeatureVisible('ApproveSupplierInvoices')
    )
  }

  private get visibleItems() {
    const result = []
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i]
      if (item.params?.page === 'supplier-invoices') {
        if (!this.isSupplierInvoicesVisible) {
          continue
        }
      }
      if (item.params?.page === 'other-settings') {
        if (!this.isOtherSettingsVisible) {
          continue
        }
      }
      result.push(item)
    }
    return result
  }
}
