




























































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class AuthorizeTwentyfourSevenOffice extends Vue {
  @Prop()
  private integration

  private isLoading = false
  private error = ''
  private identityId = ''
  private username = ''
  private password = ''
  private identities = []
  private step = 1

  private clickLogin() {
    if (!this.username || !this.password) {
      alert('Must provide username and password')
      return
    }
    this.isLoading = true
    this.$axios
      .post('/v4/site/integrations/accounting/twentyfoursevenoffice/authorize', {
        integrationId: this.integration.id,
        username: this.username,
        password: this.password,
      })
      .then((response) => {
        console.log('resp:', response.data.data)
        this.isLoading = false
        this.identities = response.data?.data?.identities || []
        console.log('ids:', this.identities)
        this.step = 2
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to fetch 24Seven identities')
        this.isLoading = false
      })
  }

  private clickAuthorize() {
    if (!this.identityId) {
      alert('Must select which company to connect to')
      return
    }
    this.isLoading = true
    this.$axios
      .post('/v4/site/integrations/accounting/twentyfoursevenoffice/authorize', {
        integrationId: this.integration.id,
        username: this.username,
        password: this.password,
        identityId: this.identityId,
        submit: true,
      })
      .then((_response) => {
        this.isLoading = false
        this.$emit('authorized')
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to authorize with 24Seven')
        this.isLoading = false
      })
  }

  private clickCancel() {
    this.$emit('cancel')
  }
}
