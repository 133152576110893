























import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import { Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class AuthorizeFortnox extends Vue {
  @Prop()
  private integration

  private isAuthorizing = false
  private isLoading = false
  private error = ''

  private clickAuthorize() {
    this.isLoading = true
    this.$axios
      .post('/v4/site/integrations/accounting/fortnox/authorize', {
        integrationId: this.integration.id,
      })
      .then((response) => {
        document.location.href = response.data.data.url
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to fetch Fortnox authorize url')
        this.isLoading = false
      })
  }

  private clickCancel() {
    this.$emit('cancel')
  }

  private created() {
    this.onRouteChange()
  }

  private clickRestart() {
    this.$router
      .push({
        name: 'Settings/Economy/AccountingIntegration/Setup',
      })
      .then(() => {
        this.clickAuthorize()
      })
  }

  @Watch('$route', { deep: true })
  private onRouteChange() {
    const isAuthorizing = this.$route.query?.install === 'authorizing'
    if (this.isAuthorizing !== isAuthorizing) {
      this.isAuthorizing = isAuthorizing
      this.verifyAuthorization()
    }
  }

  private verifyAuthorization() {
    if (!this.$route.query?.code) {
      this.error = this.$t('c:fortnox-authorize:Missing code parameter')
      return
    }
    if (!this.$route.query?.state) {
      this.error = this.$t('c:fortnox-authorize:Missing state parameter')
      return
    }

    const params = {
      code: this.$route.query?.code || '',
      state: this.$route.query?.state || '',
    }

    this.$axios
      .post('/v4/site/integrations/accounting/fortnox/authorized', params)
      .then((_response) => {
        this.$emit('authorized')
      })
      .catch((err) => {
        const message = this.$t('c:fortnox-authorize:Failed to verify authentication with Fortnox')
        vxm.alert.onAxiosError(err, message)
        this.error = message
      })
  }
}
