

















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class SupplierInvoices extends Vue {
  @Prop()
  private integration

  @Prop()
  private features

  @Prop()
  private showUnsupportedFeatures

  private get isAnyFeatureSupported() {
    return (
      this.isFeatureSupported('SupplierNumberPrefix') ||
      this.isFeatureSupported('BookkeepSupplierInvoices') ||
      this.isFeatureSupported('ApproveSupplierInvoices')
    )
  }

  private isFeatureSupported(feature: string): boolean {
    return this.features.isSupported(this.integration?.type, feature)
  }

  private isFeatureVisible(feature: string): boolean {
    return this.showUnsupportedFeatures || this.isFeatureSupported(feature)
  }

  private featureLabel(label: string, feature: string): string {
    label = this.$t(label)
    if (!this.isFeatureSupported(feature)) {
      const notSupportedText = this.$t('NOT SUPPORTED by %s integration')
      label += ' - ' + notSupportedText.replace('%s', this.integration.type)
    }
    return label
  }
}
