





























































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import Create from '@/components/economy/settings/accounting-integration/Create.vue'
import AuthorizeFortnox from '@/components/economy/settings/accounting-integration/authorize/AuthorizeFortnox.vue'
import AuthorizePowerOffice from '@/components/economy/settings/accounting-integration/authorize/AuthorizePowerOffice.vue'
import AuthorizeTripletex from '@/components/economy/settings/accounting-integration/authorize/AuthorizeTripletex.vue'
import AuthorizeTwentyfourSevenOffice from '@/components/economy/settings/accounting-integration/authorize/AuthorizeTwentyfourSevenOffice.vue'
import Overview from '@/components/economy/settings/accounting-integration/Overview.vue'
import Invoices from '@/components/economy/settings/accounting-integration/Invoices.vue'
import Receipts from '@/components/economy/settings/accounting-integration/Receipts.vue'
import SupplierInvoices from '@/components/economy/settings/accounting-integration/SupplierInvoices.vue'
import OtherSettings from '@/components/economy/settings/accounting-integration/OtherSettings.vue'
import StartNumbers from '@/components/economy/settings/accounting-integration/StartNumbers.vue'
import CheckAccounts from '@/components/economy/settings/accounting-integration/CheckAccounts.vue'
import CheckInvoices from '@/components/economy/settings/accounting-integration/CheckInvoices.vue'
import CheckCustomers from '@/components/economy/settings/accounting-integration/CheckCustomers.vue'
import SaveIntegration from '@/components/economy/settings/accounting-integration/SaveIntegration.vue'
import ShareAuthorization from '@/components/economy/settings/accounting-integration/authorize/ShareAuthorization.vue'
import { vxm } from '@/store'
import SideBar from '@/components/sidebars/economy/settings/accounting-integration/SideBar.vue'
import Features from '@/models/economy/accounting-integration/Features'
import { Watch } from 'vue-property-decorator'
import { ObjectDiff } from '@/utils/genericUtils'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component({
  components: {
    SideBar,
    Overview,
    Create,
    AuthorizeFortnox,
    AuthorizePowerOffice,
    AuthorizeTripletex,
    AuthorizeTwentyfourSevenOffice,
    ShareAuthorization,
    Invoices,
    Receipts,
    SupplierInvoices,
    OtherSettings,
    StartNumbers,
    CheckAccounts,
    CheckInvoices,
    CheckCustomers,
    SaveIntegration,
  },
})
export default class Setup extends Vue {
  private initialized = false
  private integrationId = null
  private integrations = null
  private originalIntegrations = null
  private hasUnsavedChanges = false
  private types = []
  private features: Features = null
  private showUnsupportedFeatures = false
  private installErrorMessage = '' // todo
  private validationErrors: Array<{ section: string; field: string; message: string }> = []
  private isReAuthenticate = false
  private localInvoiceNumberRanges = {}
  private checkTab = 'accounts'
  private canShareAuthorization = false

  // ==========================================================
  // Page / navigation
  // ==========================================================

  private get page(): string {
    if (!this.isInstallStepCompleted && !this.isInstallStepAuthorized) {
      // Force only first page (where setup happens), until we're authorized or completely finished
      return 'overview'
    }
    return this.$route?.params?.page || 'overview'
  }

  private get pageIsOverview(): boolean {
    return this.page === 'overview'
  }

  private get pageIsInvoices(): boolean {
    return this.page === 'invoices'
  }

  private get pageIsReceipts(): boolean {
    return this.page === 'receipts'
  }

  private get pageIsOtherSettings(): boolean {
    return this.page === 'other-settings'
  }

  private get pageIsSupplierInvoices(): boolean {
    return this.page === 'supplier-invoices'
  }

  private get pageIsStartNumbers(): boolean {
    return this.page === 'start-numbers'
  }

  private get pageIsCheck(): boolean {
    return this.page === 'checks'
  }

  private get pageIsSave(): boolean {
    return this.page === 'save'
  }

  private get title(): string {
    let title = 'Accounting integration'
    switch (this.page) {
      case 'invoices':
        title += ' - Invoices'
        break
      case 'receipts':
        title += ' - Receipts'
        break
      case 'supplier-invoices':
        title += ' - Supplier invoices'
        break
      case 'start-numbers':
        title += ' - Start numbers'
        break
      case 'checks':
        title += ' - Checks'

        break
      case 'save':
        title += ' - Save'
        break
      case 'overview':
        break
      default:
        title += ' - (' + this.page + ')' // err unknown
    }
    return title
  }

  // ==========================================================
  // Installation steps
  // ==========================================================

  private get installStep(): string {
    // (X) Special case where manually requests to re-authenticate when already authenticated
    if (this.isReAuthenticate) {
      return 'mustAuthorize'
    }

    // (3) Returned from authorizing
    if (this.$route.query?.install === 'authorizing') {
      return 'authorizing'
    }

    // (1) If no integration yet, create one (either auto from url params, or manually via button)
    if (!this.selectedIntegration) {
      return 'create'
    }

    // (5) Integration is fully installed, ignore any attempt to install again (if so must first remove existing)
    if (this.selectedIntegration.status === 'ENABLED') {
      return 'completed'
    }

    // (4) Authorized - proceed to set up rest of integration
    if (this.selectedIntegration.authorization || this.selectedIntegration.shareAuthorizationFromIntegrationId) {
      return 'authorized'
    }

    // (2) Need to authorize
    if (!this.selectedIntegration.authorization && !this.selectedIntegration.shareAuthorizationFromIntegrationId) {
      return 'notAuthorized'
    }

    // Unknown

    return 'unknown'
  }

  private get isInstallStepCreate(): boolean {
    return this.installStep === 'create'
  }

  private get isInstallStepNotAuthorized(): boolean {
    return this.installStep === 'notAuthorized'
  }

  private get isInstallStepMustAuthorize(): boolean {
    return this.installStep === 'mustAuthorize'
  }

  private get isInstallStepAuthorizing(): boolean {
    return this.installStep === 'authorizing'
  }

  private get isInstallStepAuthorized(): boolean {
    return this.installStep === 'authorized'
  }

  private get isInstallStepCompleted(): boolean {
    return this.installStep === 'completed'
  }

  private get selectedIntegration() {
    // Return integration object from the selected integration id
    if (!this.integrations) {
      return null
    }
    for (let i = 0; i < this.integrations.length; i++) {
      const integration = this.integrations[i]
      if (integration.id === this.integrationId) {
        return integration
      }
    }
    return null
  }

  private get originalSelectedIntegration() {
    // Return integration object from the selected integration id
    if (!this.originalIntegrations) {
      return null
    }
    for (let i = 0; i < this.originalIntegrations.length; i++) {
      const integration = this.originalIntegrations[i]
      if (integration.id === this.integrationId) {
        return integration
      }
    }
    return null
  }

  private get validationErrorsForPage() {
    const result = []
    for (let i = 0; i < this.validationErrors.length; i++) {
      const err = this.validationErrors[i]
      if (err.section === this.page) {
        result.push(err)
      }
    }
    return result
  }

  private get hasValidationErrorsForPage(): boolean {
    return this.validationErrorsForPage.length > 0
  }

  private get validationErrorsPerPage() {
    const result = {}
    for (let i = 0; i < this.validationErrors.length; i++) {
      const err = this.validationErrors[i]
      if (!result[err.section]) {
        result[err.section] = 0
      }
      result[err.section] += 1
    }
    return result
  }

  public created(): void {
    this.loadTypes(() => {
      this.loadFeatures(() => {
        this.loadIntegrations(() => {
          this.loadInvoiceNumberRanges(() => {
            this.initialized = true
            this.onRouteChange()
          })
        })
      })
    })
  }

  private typeIs(type: string) {
    return this.selectedIntegration.type && this.selectedIntegration.type.toLowerCase() === type?.toLowerCase()
  }

  private onCreatedIntegration(data) {
    // Once Create component is done creating a new integration, we add it and select it
    this.integrations.push(data)
    this.originalIntegrations.push(data)
    this.integrationId = data.id
    // Also navigate to get rid of the install/type url query params, since they will cause
    // an error to be displayed when an active integration is present.
    this.$router.push({
      name: 'Settings/Economy/AccountingIntegration/Setup',
    })
  }

  private onDeletedIntegration() {
    document.location.reload()
  }

  private onIntegrationStatusChanged(data) {
    this.originalSelectedIntegration.status = data.status
    this.selectedIntegration.status = data.status
  }

  // ==========================================================
  // Selected integration
  // ==========================================================

  private onReAuthenticate() {
    this.isReAuthenticate = true
  }

  private onCancelAuthorization() {
    this.isReAuthenticate = false
    this.$router.push({
      name: 'Settings/Economy/AccountingIntegration/Setup',
      query: { _ts: new Date().getTime() + '' },
    })
  }

  // ==========================================================
  // Load backend data
  // ==========================================================

  private onAuthorizedIntegration() {
    // need to reset query to avoid re-auth again for fortnox, so use this instead of reload()
    document.location.href = appendSitePrefix(
      '/settings/economy/accounting-integration/setup/overview?_refresh=' + new Date().getTime(),
    )
    // document.location.reload()
    /*
    this.selectedIntegration.authorization = '__completed__'
    this.originalSelectedIntegration.authorization = '__completed__'
    this.isReAuthenticate = false
    this.$router.push({
      name: 'Settings/Economy/AccountingIntegration/Setup',
      query: { _ts: new Date().getTime() + '' },
    })
    */
  }

  @Watch('$route', { deep: true })
  private onRouteChange() {
    // Watch route to decide if any conflicts between state and any install-action given by URL

    const install = this.$route.query?.install ? (this.$route.query.install as string) : ''
    const type = this.$route.query?.type ? (this.$route.query.type as string) : ''

    // The install=create signals setup of a new integration, then we cannot have an existing one (it must be removed first)
    if (install === 'create' && this.selectedIntegration) {
      this.installErrorMessage = this.$t(
        'You are trying to install a new integration but you already have one that is active or under installation',
      )
    }

    // The install=authorizing signals storing new auth info, and then the type/system we got auth info from must match the current integration
    if (install === 'authorizing' && this.selectedIntegration?.type?.toLowerCase() !== type.toLowerCase()) {
      this.installErrorMessage = this.$t(
        'You are trying to install authorize an integration of type %a but the type you are currently installing is %b',
      )
        .replace('%a', type.toUpperCase())
        .replace('%b', this.selectedIntegration?.type?.toUpperCase())
    }

    // When entering save page, auto-validate
    if (this.$route.params?.page === 'save') {
      this.validate()
    }
  }

  @Watch('selectedIntegration', { deep: true })
  private onSelectedIntegrationChange() {
    const differ = new ObjectDiff()
    this.hasUnsavedChanges = differ.areDifferent(this.selectedIntegration, this.originalSelectedIntegration)
  }

  private getUnsavedChanges() {
    const differ = new ObjectDiff()
    return differ.getChanges(this.selectedIntegration, this.originalSelectedIntegration)
  }

  private loadInvoiceNumberRanges(callback) {
    this.localInvoiceNumberRanges = {}
    if (!this.selectedIntegration) {
      callback()
      return
    }
    this.$axios
      .get('/v4/site/integrations/accounting/configs/' + this.selectedIntegration.id + '/check-invoice-numbers')
      .then((response) => {
        this.localInvoiceNumberRanges = response.data.data
        callback()
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not fetch invoice-number-ranges')
      })
  }

  private loadTypes(callback) {
    this.$axios
      .get('/v4/site/integrations/accounting/types')
      .then((response) => {
        this.types = response.data.data
        callback()
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not fetch integration types')
      })
  }

  private loadFeatures(callback) {
    this.$axios
      .get('/v4/site/integrations/accounting/features')
      .then((response) => {
        this.features = new Features(response.data.data.features)
        this.canShareAuthorization = response.data.data.canShareAuthorization
        callback()
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not fetch integration features')
      })
  }

  // ==========================================================
  // Validate
  // ==========================================================

  private loadIntegrations(callback) {
    this.$axios
      .get('/v4/site/integrations/accounting/configs')
      .then((response) => {
        this.integrations = response.data.data || []
        this.originalIntegrations = JSON.parse(JSON.stringify(response.data.data || '[]'))
        this.integrationId = null
        if (this.integrations && this.integrations.length > 0) {
          this.integrationId = null
          for (let i = 0; i < this.integrations.length; i++) {
            const integration = this.integrations[i]
            if (integration.status === 'ENABLED') {
              this.integrationId = integration.id
              break
            }
          }
          if (!this.integrationId) {
            this.integrationId = this.integrations[0].id
          }
        }
        callback()
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not fetch integrations')
      })
  }

  private onChangeShowUnsupportedFeatures(value) {
    this.showUnsupportedFeatures = value
  }

  private validateAndGotoNextPage() {
    const pages = ['invoices', 'receipts', 'supplier-invoices', 'start-numbers', 'checks', 'save']
    const thisPageIndex = pages.indexOf(this.page)
    const nextPage = pages[thisPageIndex + 1]
    this.validate(() => {
      if (!this.hasValidationErrorsForPage) {
        this.$router.push({
          name: 'Settings/Economy/AccountingIntegration/Setup/Page',
          params: { page: nextPage },
        })
      }
    })
  }

  private validate(callback = null) {
    this.validationErrors = []
    const integration = JSON.parse(JSON.stringify(this.selectedIntegration)) // may adjust for backend, so use a clone instead of original

    // Do some validation here, to pass micropop type checks etc.

    if (!integration.invoiceDistributionStrategy) {
      this.addValidationError('invoices', 'invoiceDistributionStrategy', 'Must choose how invoices should be sent')
      integration.invoiceDistributionStrategy = 'OurNumberOurDistribution' // make backend pass micropop
    }

    if (integration.startNumbers) {
      let gotInvoiceStartNumber = false
      for (let i = 0; i < integration.startNumbers.length; i++) {
        const sn = integration.startNumbers[i]
        switch (sn.objectType) {
          case 'RECEIPT':
            if (!sn.startNumber) {
              this.addValidationError(
                'start-numbers',
                'startNumber' + i,
                'Must enter start receipt number for register ' + sn.registerNumber,
              )
              sn.startNumber = 1 // make backend pass micropop
            } else if (!/^\d+$/.test(sn.startNumber)) {
              this.addValidationError(
                'start-numbers',
                'startNumber' + i,
                'Invalid start receipt number for register ' + sn.registerNumber,
              )
              sn.startNumber = 1 // make backend pass micropop
            }
            break
          case 'INVOICE':
            if (!sn.startNumber) {
              this.addValidationError(
                'start-numbers',
                'startNumber:' + i,
                'Must enter start receipt number for invoices',
              )
              sn.startNumber = 1 // make backend pass micropop
            } else if (!/^\d+$/.test(sn.startNumber)) {
              this.addValidationError('start-numbers', 'startNumber:' + i, 'Invalid start number for invoices ')
              sn.startNumber = 1 // make backend pass micropop
            }
            gotInvoiceStartNumber = true
            break
        }
      }
      if (!gotInvoiceStartNumber) {
        this.addValidationError(
          'start-numbers',
          'startNumber:invoices',
          'Must enter start receipt number for invoices',
        )
      }
    }

    // Leave more advanced validations (features etc) to backend

    this.$axios
      .post('/v4/site/integrations/accounting/validate', integration)
      .then((response) => {
        if (response.data.data?.errors) {
          for (let i = 0; i < response.data.data.errors.length; i++) {
            this.validationErrors.push(response.data.data.errors[i])
          }
        }
        if (callback) {
          callback()
        }
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed validating integration data')
      })
  }

  private addValidationError(section: string, field: string, message: string) {
    this.validationErrors.push({
      section: section,
      field: field,
      message: message,
    })
  }

  // ==========================================================
  // Save settings
  // ==========================================================

  private save() {
    this.validate(() => {
      if (this.validationErrors.length > 0) {
        return
      }
      this.$axios
        .put('/v4/site/integrations/accounting/configs/' + this.selectedIntegration.id, this.selectedIntegration)
        .then((response) => {
          // Update integration from backend response
          const updated = response.data.data
          for (let i = 0; i < this.integrations.length; i++) {
            if (this.integrations[i].id === updated.id) {
              Object.assign(this.integrations[i], updated)
              break
            }
          }
          for (let i = 0; i < this.originalIntegrations.length; i++) {
            if (this.originalIntegrations[i].id === updated.id) {
              Object.assign(this.originalIntegrations[i], updated)
              break
            }
          }
          this.hasUnsavedChanges = false
          // Redirect to overview
          this.$router.push({
            name: 'Settings/Economy/AccountingIntegration/Setup',
          })
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Failed saving integration settings')
        })
    })
  }
}
