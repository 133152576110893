




























import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class AuthorizePowerOffice extends Vue {
  @Prop()
  private integration

  private isLoading = false
  private error = ''
  private clientKey = ''

  private clickAuthorize() {
    if (!this.clientKey) {
      alert('Must provide a client Key')
      return
    }
    this.isLoading = true
    this.$axios
      .post('/v4/site/integrations/accounting/poweroffice/authorize', {
        integrationId: this.integration.id,
        clientKey: this.clientKey,
      })
      .then((response) => {
        console.log('success auth:', response.data.data)
        this.isLoading = false
        this.$emit('authorized')
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to authorize with PowerOffice')
        this.isLoading = false
      })
  }

  private clickCancel() {
    this.$emit('cancel')
    /*
    if (this.integration?.id && !this.integration.authorization) {
      this.$axios
        .delete('/v4/site/integrations/accounting/configs/' + this.integration.id)
        .then((_response) => {
          this.$emit('deleted')
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Could not delete integration')
        })
    } else {
      this.$emit('deleted')
    }
    */
  }
}
