




























































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { vxm } from '@/store'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component({
  components: {},
})
export default class CheckCustomers extends Vue {
  @Prop()
  private integration

  private isLoading = false
  private step = 1
  private data = null
  private sampleCustomerNumbersStart = null
  private sampleCustomerNumbersLength = null
  private sampleCustomerNumbersLengthMax = null

  private clickNextSamples() {
    this.sampleCustomerNumbersStart =
      parseInt(this.sampleCustomerNumbersStart) + parseInt(this.sampleCustomerNumbersLength)
    this.load()
  }

  private load() {
    this.isLoading = true
    let query = 'getRemote=1&getSamples=1'
    if (this.sampleCustomerNumbersStart) {
      query += '&sampleCustomerNumbersStart=' + this.sampleCustomerNumbersStart
    }
    if (this.sampleCustomerNumbersLength) {
      query += '&sampleCustomerNumbersLength=' + this.sampleCustomerNumbersLength
    }
    this.$axios
      .get('/v4/site/integrations/accounting/configs/' + this.integration.id + '/check-customer-numbers?' + query)
      .then((response) => {
        this.data = response.data.data
        this.step = 2
        this.isLoading = false
        this.sampleCustomerNumbersStart = response.data.data.sampleCustomerNumbersStart
        this.sampleCustomerNumbersLength = response.data.data.sampleCustomerNumbersLength
        this.sampleCustomerNumbersLengthMax = response.data.data.sampleCustomerNumbersLengthMax
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to check customer-numbers')
        this.isLoading = false
      })
  }

  private getUrl(url: string): string {
    return appendSitePrefix(url)
  }

  private getSampleClass(row) {
    return this.isSampleOk(row) ? 'sample-ok' : 'sample-error'
  }

  private isSampleOk(row) {
    if (row.local && row.remote) {
      // If both sides exist, they must be same customer (name will have to do)
      const localName = row.local.name ? (row.local.name + '').toLowerCase() : ''
      const remoteName = row.remote.name ? (row.remote.name + '').toLowerCase() : ''
      if (localName && remoteName && localName === remoteName) {
        return true
      } else {
        return false
      }
    } else {
      // Either only one side, or no sides, exists, which is fine
      return true
    }
  }

  private get sampleRangeText() {
    const numbers =
      'Customers between ' +
      this.data.sampleCustomerNumbersStart +
      ' and ' +
      (this.data.sampleCustomerNumbersStart + this.data.sampleCustomerNumbersLength)
    return this.isSampleRangeOk() ? numbers + ' are ok' : numbers + ' are not ok'
  }

  private get sampleRangeClass() {
    return this.isSampleRangeOk() ? 'sample-range-ok' : 'sample-range-error'
  }

  private isSampleRangeOk() {
    for (let i = 0; i < this.data.samples.length; i++) {
      if (!this.isSampleOk(this.data.samples[i])) {
        return false
      }
    }
    return true
  }
}
