























































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import UnsafeChange from '@/components/economy/settings/accounting-integration/UnsafeChange.vue'

@Component({
  components: {
    UnsafeChange,
  },
})
export default class Receipts extends Vue {
  @Prop()
  private integration

  @Prop()
  private features

  @Prop()
  private showUnsupportedFeatures

  private receiptSendingModeVirtual = ''

  public mounted() {
    this.receiptSendingModeVirtual = ''
    if (this.integration.transferCashRegisterTransactionsAsInvoices) {
      this.receiptSendingModeVirtual = 'Invoice'
    } else {
      this.receiptSendingModeVirtual = this.integration.receiptSendingMode
    }
  }

  @Watch('receiptSendingModeVirtual')
  private onReceiptSendingModeVirtualChange() {
    switch (this.receiptSendingModeVirtual) {
      case 'Invoice':
        this.integration.transferCashRegisterTransactionsAsInvoices = true
        break
      default:
        this.integration.transferCashRegisterTransactionsAsInvoices = false
        this.integration.receiptSendingMode = this.receiptSendingModeVirtual
    }
  }

  private isFeatureSupported(feature: string): boolean {
    return this.features.isSupported(this.integration?.type, feature)
  }

  private isFeatureVisible(feature: string): boolean {
    return this.showUnsupportedFeatures || this.isFeatureSupported(feature)
  }

  private featureLabel(label: string, feature: string): string {
    label = this.$t(label)
    if (!this.isFeatureSupported(feature)) {
      const notSupportedText = this.$t('NOT SUPPORTED by %s integration')
      label += ' - ' + notSupportedText.replace('%s', this.integration.type)
    }
    return label
  }

  private get deprecationWarning() {
    if (!this.isFeatureSupported('ReceiptSendingModeDailyBatchWithSummedPayments')) {
      return ''
    }
    if (this.receiptSendingModeVirtual === 'DailyBatchWithSummedPayments') {
      return ''
    }
    return 'Please use "One voucher per day - with summed payment postings", unless you have a very good reason not to. All other options will be deprecated in the future.'
  }
}
