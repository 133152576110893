

































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { vxm } from '@/store'

@Component({
  components: {},
})
export default class Overview extends Vue {
  @Prop()
  private integration

  private checkWorkingResult = null
  private checkWorkingDialog = false
  private isLoading = false

  private clickConfigure() {
    this.$router.push({
      name: 'Settings/Economy/AccountingIntegration/Setup/Page',
      params: { page: 'invoices' },
    })
  }

  private clickPause() {
    this.changeIntegrationStatus('DISABLED')
  }

  private clickActivate() {
    this.changeIntegrationStatus('ENABLED')
  }

  private get isAuthorized() {
    return this.integration.authorization || this.integration.shareAuthorizationFromIntegrationId
  }

  private changeIntegrationStatus(status: string) {
    const integration = this.cloneIntegration()
    integration.status = status
    this.$axios
      .put('/v4/site/integrations/accounting/configs/' + this.integration.id + '/set-status', integration)
      .then((response) => {
        this.$emit('statusChanged', { status: response.data.data.status })
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not change integration status')
      })
  }

  private clickDelete() {
    const message =
      this.$t('The integration will be PERMANENTLY REMOVED.') +
      '\n\n' +
      this.$t('This will also REMOVE ALL HISTORY of what has been transferred to the accounting system.') +
      '\n\n' +
      this.$t('There is NO WAY TO UNDO THIS.') +
      '\n\n' +
      this.$t('Are you sure?')
    if (!confirm(message)) {
      return
    }
    this.$axios
      .delete('/v4/site/integrations/accounting/configs/' + this.integration.id)
      .then((_response) => {
        this.$emit('deleted')
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not delete integration')
      })
  }

  private clickReAuthenticate() {
    this.$emit('reauthenticate')
  }

  private clickCheckWorking() {
    this.isLoading = true
    this.$axios
      .get('/v4/site/integrations/accounting/configs/' + this.integration.id + '/check')
      .then((response) => {
        this.checkWorkingResult = response.data.data
        this.checkWorkingDialog = true
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not delete integration')
        this.isLoading = false
      })
  }

  private cloneIntegration() {
    return JSON.parse(JSON.stringify(this.integration))
  }
}
