class Feature {
  public feature: string
  public types: Array<string>
}

export default class Features {
  private data = []

  public constructor(data: Array<Record<string, unknown>>) {
    for (let i = 0; i < data.length; i++) {
      const f = new Feature()
      f.feature = data[i].feature as string
      f.types = data[i].types as Array<string>
      this.data.push(f)
    }
  }

  public isSupported(type: string, feature: string): boolean {
    if (!this.data) {
      return false
    }
    if (!type) {
      return false
    }
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].feature === feature) {
        return this.data[i].types.indexOf(type) !== -1
      }
    }
    return false
  }
}
