



















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class UnsafeChange extends Vue {
  @Prop()
  private integration

  @Prop()
  private link: string
}
