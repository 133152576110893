var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.integration)?_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isAnyFeatureSupported),expression:"!isAnyFeatureSupported"}]},[_c('div',{staticStyle:{"margin-top":"20px"}},[_vm._v(" "+_vm._s(_vm.$t('Integration of type %s does not yet support supplier invoices').replace('%s', _vm.integration.type))+" ")])]),_c('h3',{directives:[{name:"show",rawName:"v-show",value:(_vm.isFeatureVisible('SupplierNumberPrefix')),expression:"isFeatureVisible('SupplierNumberPrefix')"}]},[_vm._v(" "+_vm._s(_vm.$t('c:accounting-integration:Supplier number adjustments'))+" "),_c('small',{staticClass:"optional"},[_vm._v("("+_vm._s(_vm.$t('c:accounting-integration:Optional'))+")")])]),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.isFeatureVisible('SupplierNumberPrefix')),expression:"isFeatureVisible('SupplierNumberPrefix')"}],staticStyle:{"margin-top":"20px"},attrs:{"label":_vm.featureLabel('c:accounting-integration:Supplier number prefix', 'SupplierNumberPrefix'),"outlined":"","persistent-hint":"","hint":_vm.$t(
        'F.ex: if prefix is "EON" and EONTYRE supplier number is 1234 then accounting system supplier number will be EON1234'
      ),"disabled":!_vm.isFeatureSupported('SupplierNumberPrefix')},model:{value:(_vm.integration.supplierNumberPrefix),callback:function ($$v) {_vm.$set(_vm.integration, "supplierNumberPrefix", $$v)},expression:"integration.supplierNumberPrefix"}}),_c('h3',{directives:[{name:"show",rawName:"v-show",value:(_vm.isFeatureVisible('BookkeepSupplierInvoices')),expression:"isFeatureVisible('BookkeepSupplierInvoices')"}]},[_vm._v(" "+_vm._s(_vm.$t('c:accounting-integration:Book supplier invoices'))+" "),_c('small',{staticClass:"optional"},[_vm._v("("+_vm._s(_vm.$t('c:accounting-integration:Optional'))+")")])]),_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.isFeatureVisible('BookkeepSupplierInvoices')),expression:"isFeatureVisible('BookkeepSupplierInvoices')"}],staticStyle:{"margin-top":"20px"},attrs:{"label":_vm.featureLabel('c:accounting-integration:Book supplier invoices', 'BookkeepSupplierInvoices'),"outlined":"","persistent-hint":"","hint":_vm.$t('Book supplier invoices automatically in accounting system immediately after it they are sent from EONTYRE'),"disabled":!_vm.isFeatureSupported('BookkeepCustomerInvoices'),"items":[
      { id: true, name: 'Yes' },
      { id: false, name: 'No' } ]},model:{value:(_vm.integration.bookkeepSupplierInvoices),callback:function ($$v) {_vm.$set(_vm.integration, "bookkeepSupplierInvoices", $$v)},expression:"integration.bookkeepSupplierInvoices"}}),_c('h3',{directives:[{name:"show",rawName:"v-show",value:(_vm.isFeatureVisible('ApproveSupplierInvoices')),expression:"isFeatureVisible('ApproveSupplierInvoices')"}]},[_vm._v(" "+_vm._s(_vm.$t('c:accounting-integration:Approve supplier invoices'))+" "),_c('small',{staticClass:"optional"},[_vm._v("("+_vm._s(_vm.$t('c:accounting-integration:Optional'))+")")])]),_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.isFeatureVisible('ApproveSupplierInvoices')),expression:"isFeatureVisible('ApproveSupplierInvoices')"}],staticStyle:{"margin-top":"20px"},attrs:{"label":_vm.featureLabel('c:accounting-integration:Approve supplier invoices', 'ApproveSupplierInvoices'),"outlined":"","persistent-hint":"","hint":_vm.$t(
        'Approve supplier invoices automatically in accounting system immediately after it they are sent from EONTYRE'
      ),"disabled":!_vm.isFeatureSupported('ApproveSupplierInvoices'),"items":[
      { id: true, name: 'Yes' },
      { id: false, name: 'No' } ]},model:{value:(_vm.integration.approveSupplierInvoices),callback:function ($$v) {_vm.$set(_vm.integration, "approveSupplierInvoices", $$v)},expression:"integration.approveSupplierInvoices"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }