
































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import { Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Create extends Vue {
  private loading = false
  private error = ''
  private step = 1
  private type = ''

  @Prop()
  private types

  // 1. First we show that no integration is configured, you click to proceed to select type

  private clickConfigure() {
    this.step = 2
  }

  // 2. Second you select a type, you click to proceed to create the integration
  // We navigate to signal the intention to create.
  // NOTE: We can also jump directly to the create step from outside by passing the relevant url query params.

  private clickCreate() {
    this.error = ''
    if (!this.type) {
      this.error = this.$t('c:accounting-integration:You must choose an integration type')
      return
    }
    if (!this.isValidType(this.type)) {
      this.error = this.$t('c:accounting-integration:Invalid type selected')
      return
    }
    this.$router.push({
      name: 'Settings/Economy/AccountingIntegration/Setup',
      query: {
        install: 'create',
        type: this.type,
      },
    })
  }

  // 3. Once we see the relevant url query params we execute creation of the integration,
  // and finally emit to parent that we're done.

  private created() {
    this.onRouteChange()
  }

  @Watch('$route', { deep: true })
  private onRouteChange() {
    if (this.$route.query?.install === 'create' && this.$route.query?.type) {
      const type = this.$route.query.type
      if (!this.isValidType(type)) {
        this.error = this.$t('c:accounting-integration:Invalid type for auto-started install')
        return
      }
      this.createIntegration(type)
    }
  }

  private isValidType(type): boolean {
    if (!type) {
      return false
    }
    for (let i = 0; i < this.types.length; i++) {
      if (this.types[i].code.toLowerCase() === type.toLowerCase()) {
        return true
      }
    }
    return false
  }

  private createIntegration(type) {
    this.loading = true
    this.$axios
      .post('/v4/site/integrations/accounting/configs', { type: type })
      .then((response) => {
        if (response.data.error) {
          this.error = response.data.error
          this.loading = false
        } else {
          this.$emit('created', response.data.data)
        }
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not save integration')
        this.error = this.$t('Could not save integration')
        this.loading = false
      })
  }
}
