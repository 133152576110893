




























import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class AuthorizeTripletex extends Vue {
  @Prop()
  private integration

  private isLoading = false
  private error = ''
  private employeeToken = ''

  private clickAuthorize() {
    if (!this.employeeToken) {
      alert('Must provide a client Key')
      return
    }
    this.isLoading = true
    this.$axios
      .post('/v4/site/integrations/accounting/tripletex/authorize', {
        integrationId: this.integration.id,
        employeeToken: this.employeeToken,
      })
      .then((response) => {
        console.log('success auth:', response.data.data)
        this.isLoading = false
        this.$emit('authorized')
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to authorize with Tripletex')
        this.isLoading = false
      })
  }

  private clickCancel() {
    this.$emit('cancel')
  }
}
