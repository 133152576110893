import { render, staticRenderFns } from "./SideBar.vue?vue&type=template&id=cdb4b288&scoped=true&"
import script from "./SideBar.vue?vue&type=script&lang=ts&"
export * from "./SideBar.vue?vue&type=script&lang=ts&"
import style0 from "./SideBar.vue?vue&type=style&index=0&id=cdb4b288&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdb4b288",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBadge,VCheckbox,VIcon,VList,VListItem,VListItemContent,VListItemIcon,VListItemSubtitle,VListItemTitle,VSelect})
