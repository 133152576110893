export class ObjectDiff {
  private VALUE_CREATED = 'created'
  private VALUE_UPDATED = 'updated'
  private VALUE_DELETED = 'deleted'
  private VALUE_UNCHANGED = 'unchanged'
  private sloppy: boolean

  public constructor(sloppy = true) {
    this.sloppy = sloppy
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public areDifferent(obj1: Object, obj2: Object): boolean {
    const changes = []
    const stack = ['root']
    this.map(obj1, obj2, changes, stack)
    return changes.length > 0
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public getChanges(obj1: Object, obj2: Object): Array<{ type: string; key: string }> {
    const changes = []
    const stack = ['root']
    this.map(obj1, obj2, changes, stack)
    return changes
  }

  // todo: could make this public, to make actual changes available, but if so must fix types first (original was from vanilla js)
  // todo: it's all a bit messy... should clean it up
  private map(obj1, obj2, changes, stack) {
    if (this.isFunction(obj1) || this.isFunction(obj2)) {
      throw Error('Invalid argument. Function given, object expected.')
    }
    if (this.isValue(obj1) || this.isValue(obj2)) {
      const type = this.compareValues(obj1, obj2)
      if (type !== this.VALUE_UNCHANGED) {
        changes.push({ type: type, key: stack.join('.') })
      }
      return {
        type: type,
        data: obj1 === undefined ? obj2 : obj1,
      }
    }

    const diff = {}
    for (const key in obj1) {
      if (this.isFunction(obj1[key])) {
        continue
      }

      let value2
      if (obj2[key] !== undefined) {
        value2 = obj2[key]
      }

      stack.push(key)
      diff[key] = this.map(obj1[key], value2, changes, stack)
      stack.pop()
    }
    for (const key in obj2) {
      if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
        continue
      }

      stack.push(key)
      diff[key] = this.map(undefined, obj2[key], changes, stack)
      stack.pop()
    }

    return diff
  }

  private compareValues(value1, value2) {
    if (value1 === value2) {
      return this.VALUE_UNCHANGED
    }
    if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
      return this.VALUE_UNCHANGED
    }
    if (this.sloppy) {
      if ('' + value1 === '' + value2) {
        return this.VALUE_UNCHANGED
      }
    }
    if (value1 === undefined) {
      return this.VALUE_CREATED
    }
    if (value2 === undefined) {
      return this.VALUE_DELETED
    }
    return this.VALUE_UPDATED
  }

  private isFunction(x) {
    return Object.prototype.toString.call(x) === '[object Function]'
  }

  private isArray(x) {
    return Object.prototype.toString.call(x) === '[object Array]'
  }

  private isDate(x) {
    return Object.prototype.toString.call(x) === '[object Date]'
  }

  private isObject(x) {
    return Object.prototype.toString.call(x) === '[object Object]'
  }

  private isValue(x) {
    return !this.isObject(x) && !this.isArray(x)
  }
}
