




















































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class ShareAuthorization extends Vue {
  @Prop()
  private integration

  private isLoading = false
  private error = ''
  private shareableIntegrations = []
  private shareFromIntegrationId = null

  private created() {
    this.isLoading = true
    this.$axios
      .get('/v4/site/integrations/accounting/configs/' + this.integration.id + '/shareable-authorizations', {
        integrationId: this.integration.id,
      })
      .then((response) => {
        this.shareableIntegrations = response.data.data
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to fetch shareable authorizations')
        this.isLoading = false
      })
  }

  private get haveShareables() {
    return this.shareableIntegrations && this.shareableIntegrations.length > 0
  }

  private clickSave() {
    if (!this.shareFromIntegrationId) {
      alert('Must select an integration to share from')
      return
    }
    if (!confirm('Are you sure?')) {
      return
    }
    this.isLoading = true
    this.$axios
      .post('/v4/site/integrations/accounting/configs/' + this.integration.id + '/share-authorization', {
        shareFromIntegrationId: this.shareFromIntegrationId,
      })
      .then((_response) => {
        this.$emit('authorized')
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to save shared authorization')
        this.isLoading = false
      })
  }

  private clickCancel() {
    this.$emit('cancel')
  }
}
