
































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { ObjectDiff } from '@/utils/genericUtils'

@Component({
  components: {},
})
export default class StartNumbers extends Vue {
  @Prop()
  private integration

  @Prop()
  private originalIntegration

  @Prop()
  private features

  @Prop()
  private showUnsupportedFeatures

  @Prop()
  private localInvoiceNumberRange

  private get invoiceStartNumber(): string {
    if (this.integration?.startNumbers) {
      for (let i = 0; i < this.integration.startNumbers.length; i++) {
        const s = this.integration.startNumbers[i]
        if (s.objectType === 'INVOICE') {
          return s.startNumber + ''
        }
      }
    }
    return ''
  }

  private set invoiceStartNumber(number: string) {
    for (let i = 0; i < this.integration.startNumbers.length; i++) {
      const s = this.integration.startNumbers[i]
      if (s.objectType === 'INVOICE') {
        s.startNumber = number
        return
      }
    }
    this.integration.startNumbers.push({
      objectType: 'INVOICE',
      startNumber: number,
    })
  }

  private get invoiceStartNumberForPayments(): string {
    if (this.integration?.startNumbers) {
      for (let i = 0; i < this.integration.startNumbers.length; i++) {
        const s = this.integration.startNumbers[i]
        if (s.objectType === 'INVOICEPAYMENT') {
          return s.startNumber + ''
        }
      }
    }
    return ''
  }

  private set invoiceStartNumberForPayments(number: string) {
    for (let i = 0; i < this.integration.startNumbers.length; i++) {
      const s = this.integration.startNumbers[i]
      if (s.objectType === 'INVOICEPAYMENT') {
        s.startNumber = number
        return
      }
    }
    this.integration.startNumbers.push({
      objectType: 'INVOICEPAYMENT',
      startNumber: number,
    })
  }

  private get receiptStartNumbers() {
    const result = []
    for (let i = 0; i < this.integration.startNumbers.length; i++) {
      const s = this.integration.startNumbers[i]
      if (s.objectType === 'RECEIPT') {
        result.push(s)
      }
    }
    return result
  }

  private addReceiptStartNumber() {
    let max = 0
    for (let i = 0; i < this.integration.startNumbers.length; i++) {
      const s = this.integration.startNumbers[i]
      if (s.objectType === 'RECEIPT' && s.registerNumber > max) {
        max = s.registerNumber
      }
    }
    this.integration.startNumbers.push({
      objectType: 'RECEIPT',
      registerNumber: max + 1,
      receiptNumber: '',
    })
  }

  private removeStartNumber(n) {
    const result = []
    for (let i = 0; i < this.integration.startNumbers.length; i++) {
      const s = this.integration.startNumbers[i]
      if (s !== n) {
        result.push(s)
      }
    }
    console.log('res:', result)
    this.integration.startNumbers = result
  }

  private get hasReceiptStartNumbers(): boolean {
    if (this.integration.startNumbers) {
      for (let i = 0; i < this.integration.startNumbers.length; i++) {
        const s = this.integration.startNumbers[i]
        if (s.objectType === 'RECEIPT' && s.registerNumber > 0) {
          return true
        }
      }
    }
    return false
  }

  private get showChangeText() {
    if (!this.integration.lastSuccessfulTransferTime) {
      return false
    }
    const differ = new ObjectDiff()
    return differ.areDifferent(this.integration.startNumbers, this.originalIntegration.startNumbers)
  }

  private get invoiceNumbersRangeText() {
    if (this.localInvoiceNumberRange?.localMinInvoiceNumber) {
      return (
        'Invoices exist in EONTYRE between numbers ' +
        this.localInvoiceNumberRange.localMinInvoiceNumber +
        ' - ' +
        this.localInvoiceNumberRange.localMaxInvoiceNumber
      )
    } else {
      return 'No invoices exist yet'
    }
  }
}
