














































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { vxm } from '@/store'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component({
  components: {},
})
export default class CheckInvoices extends Vue {
  @Prop()
  private integration

  private isLoading = false
  private step = 1
  private data = null

  private get invoiceStartNumber(): string {
    for (let i = 0; i < this.integration.startNumbers.length; i++) {
      const s = this.integration.startNumbers[i]
      if (s.objectType === 'INVOICE') {
        return s.startNumber + ''
      }
    }
    return ''
  }

  private load() {
    this.isLoading = true
    this.$axios
      .get('/v4/site/integrations/accounting/configs/' + this.integration.id + '/check-invoice-numbers?getRemote=1')
      .then((response) => {
        this.data = response.data.data
        this.step = 2
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to check invoice-numbers')
        this.isLoading = false
      })
  }

  private getUrl(url: string): string {
    return appendSitePrefix(url)
  }
}
